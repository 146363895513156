import React from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SectionButton from "../SectionButton";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Quote from "../Quote";

function QuoteSection(props) {
  const {
    buttonText,
    color,
    inputLabel,
    inputPlaceholder,
    size,
    subscribedMessage,
    subtitle,
    title,
    buttonOnClick,
    ...otherProps
  } = props;

  return (
    <Section color={color} size={size} {...otherProps}>
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <SectionHeader size={4} subtitle={subtitle} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign='center'>
              <SectionButton
                parentColor={color}
                size="large"
                onClick={buttonOnClick}
              >
                Contact Us
              </SectionButton>
            </Box>
            {/* <Quote
              parentColor={color}
              buttonText={buttonText}
              inputLabel={inputLabel}
              inputPlaceholder={inputPlaceholder}
              subscribedMessage={subscribedMessage}
              size="medium"
            /> */}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

QuoteSection.propTypes = {
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  color: PropTypes.string,
  inputLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  size: PropTypes.string,
  subscribedMessage: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default QuoteSection;
