import React from "react";
import { Helmet } from 'react-helmet'
import HeroSection from "../../components/HeroSection";
import ProvidersSection from "../../components/ProvidersSection";
import ServicesSection from "./../../components/ServicesSection";
import TestimonialsSection from "./../../components/TestimonialsSection";
import QuoteSection from "../../components/QuoteSection";
import { useRouter } from "./../../util/router.js";
import config from "../../util/config.js"
import heroImage from "../../images/section-hero.svg";

function HomePage() {
  const router = useRouter();

  return (
    <>
      <Helmet>
        <title>{config.meta.siteName} | {config.meta.tagline}</title>
      </Helmet>
      <HeroSection
        color="background.alternate"
        size="fold"
        title="We Make E-Commerce A Breeze"
        subtitle="Working with industry leading providers to create, host and manage your online store leaving you to do what you do best – run your business."
        buttonText="Get a Quote"
        image={heroImage}
        imageAlt="Hassle-Free E-Commerce Solutions"
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
      <ProvidersSection
        color="background.default"
        size="normal"
        title=""
        subtitle=""
      />
      <ServicesSection
        color="background.alternate"
        size="medium"
        title="Services"
        subtitle=""
      />
      {/* <TestimonialsSection
        color="background.alternate"
        size="medium"
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <QuoteSection
        color="secondary.main"
        size="medium"
        title="Contact Us Now!"
        subtitle="Not sure where to start? We'd be happy to provide a free quote for your project. Head on over to our contact form and drop us a line, we'll react out to you as soon as possible."
        buttonText="Submit"
        inputLabel="Enter your email"
        inputPlaceholder="user@example.com"
        subscribedMessage="Thank you for your interest!"
        buttonOnClick={() => {
          router.push("/contact");
        }}
      />
    </>
  );
}

export default HomePage;
