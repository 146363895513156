import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

function Providers(props) {
  const { items, ...otherProps } = props;
  return (
    <Grid container justify="center" {...otherProps}>
      <Grid
        container
        item
        xs={3}
        md={12}
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        {items.map((item, index) => (
          <Grid item key={index}>
            <a href={item.link} target="_blank noreferrer"><img src={item.image} width={item.width} alt={item.name} /></a>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

Providers.propTypes = {
  items: PropTypes.array
};

export default Providers;
