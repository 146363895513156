import React from "react";
import PropTypes from "prop-types";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Services from "./../Services";
import Ecommerce from "../../images/service-ecommerce.svg";
import CustomWebsites from "../../images/service-customwebsites.svg";
import Hosting from "../../images/service-hosting.svg";
import Social from "../../images/service-social.svg";

function ServicesSection(props) {
  const { color, size, subtitle, title, ...otherProps } = props;
  return (
    <Section color={color} size={size} {...otherProps}>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        centered={true}
        size={4}
      />
      <Services
        items={[
          {
            title: "E-Commerce",
            description:
              "Customized e-commerce solutions utilizing WordPress / WooCommerce and your choice of payment gateway.",
            image: `${Ecommerce}`
          },
          {
            title: "Custom Websites",
            description:
              "From simple landing pages to full fledged web applications Web Solutions has you covered. We also can migrate projects from other providers to our platform.",
            image:
              `${CustomWebsites}`
          },
          {
            title: "Hosting / Domains",
            description:
              "Tired of dealing with confusing hosting providers and overpriced domains? All of our applications are hosted on virtual private servers on industry leading cloud infrastructure.",
            image: `${Hosting}`
          },
          {
            title: "Social Media",
            description:
              "These days social media presence isn't just a suggestion - it's required! Let us manage your organizations social media to simplify the process.",
            image: `${Social}`
          }
        ]}
      />
    </Section>
  );
}

ServicesSection.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default ServicesSection;
