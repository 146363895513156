import { createMuiTheme } from "@material-ui/core/styles";
import config from "./util/config.js"

const theme = createMuiTheme({
  palette: {
    type: config.theme.type,
    primary: {
      main: config.theme.primary.main
    },
    background: {
      default: config.theme.background.default,
      alternate: config.theme.background.alternate
    },
    success: { main: "#23D160" },
    warning: { main: "#FFDD57", },
    danger: { main: "#FF3860", },
    info: { main: "#209CEE" },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans",
    ].join(","),
    h3: {
      fontWeight: 600
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    }
  }
});

export default theme;
