var config = {
    formAPI: {
        url: "https://formio.stringercreative.co/webSolutionsContactForm"
    },
    meta: {
        siteName: "Web Solutions",
        siteNameLegal: "Web Solutions, LLC",
        tagline: "Developing For Tomorrow, Today",
        establishedYear: "2020",
        social: {
            twitter: "https://www.twitter.com/",
            facebook: "https://www.facebook.com/",
            instagram: "https://www.instagram.com/"
        },
        address: "208 S Washington Street",
        city: "McLeansboro",
        state: "Illinois",
        zip: "62859",
        phone: "(618) 643 - 7337",
        email: ""
    },
    theme: {
        type: "dark",
        primary: {
            main: "#50c7f5"
        },
        background: {
            default: "#1b1b1b",
            alternate: "#222"
        }
    }
}

export default config;