import { React, useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { Redirect } from 'react-router'

function DelayedRedirect(props) {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRedirect(true)
        }, props.time)
    });

    return (
        <>
            {redirect && (<Redirect to={props.url} />)}
        </>
    )

}

DelayedRedirect.propTypes = {
    time: PropTypes.string,
    url: PropTypes.string,
};

export default DelayedRedirect