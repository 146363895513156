import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Contact from "../Contact";

const useStyles = makeStyles({
  name: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.75em"
  },
  address: {
    marginTop: "1.25em",
    textAlign: "center"
  },
  address2: {
    textAlign: "center"
  },
  email: {
    marginTop: "1.25em",
    textAlign: "center"
  },
  phone: {
    marginTop: "1.25em",
    fontSize: "1.5em",
    textAlign: "center",
    fontWeight: "bold",
  }
});

function ContactSection(props) {
  const classes = useStyles();

  const {
    buttonText,
    color,
    showNameField,
    size,
    subtitle,
    title,
    contactInfo,
    ...otherProps
  } = props;

  return (
    <Section color={color} size={size} {...otherProps}>
      <Container maxWidth="md">
        <SectionHeader
          title={title}
          subtitle={subtitle}
          centered={true}
          size={4}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={contactInfo ? 8 : 12}>
            <Contact
              parentColor={color}
              showNameField={showNameField}
              buttonText={buttonText}
            />
          </Grid>
          {contactInfo && (
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  {contactInfo.meta.siteNameLegal && (
                    <Typography className={classes.name} color="textSecondary">{contactInfo.meta.siteNameLegal}</Typography>
                  )}
                  {contactInfo.meta.address && (
                    <>
                      <Typography className={classes.address} color="textSecondary">{contactInfo.meta.address}</Typography>
                      <Typography className={classes.address2} color="textSecondary">{contactInfo.meta.city}, {contactInfo.meta.state} {contactInfo.meta.zip}</Typography>
                    </>
                  )}
                  {contactInfo.meta.email && (
                    <Typography className={classes.email} color="textSecondary"><Link href={'mailto:' + contactInfo.meta.email}>{contactInfo.meta.email}</Link></Typography>
                  )}
                  {contactInfo.meta.phone && (
                    <Typography className={classes.phone} color="textSecondary"><Link href={'tel:' + contactInfo.meta.phone}>{contactInfo.meta.phone}</Link></Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Section >
  );
}

ContactSection.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.string,
  showNameField: PropTypes.bool,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  contactInfo: PropTypes.object
};

export default ContactSection;
