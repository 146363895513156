import React, { useState } from "react";
import { Formio } from 'formiojs';
import PropTypes from "prop-types";
import ContactForm from "../ContactForm";
import config from "../../util/config.js";

let formio = new Formio(config.formAPI.url);

function Contact(props) {
  const { buttonText, parentColor, showNameField, ...otherProps } = props;
  const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message }) => {
    let currentdate = new Date();
    let date = currentdate.getMonth() + "/"
      + currentdate.getDate() + "/"
      + currentdate.getFullYear() + " "
      + currentdate.getHours() + ":"
      + currentdate.getMinutes() + ":"
      + currentdate.getSeconds();

    setStatus({ type: "pending" });
    formio.saveSubmission({
      data: {
        name: name,
        email: email,
        message: message,
        date: date
      }
    }).then((r) => {
      console.log(r)
      setStatus({
        type: "success",
        message: "Thank you! We appreciate your interest."
      });
    }).catch(err => {
      if (err) {
        console.log(err)
        setStatus({
          type: "error",
          message: "There was an issue submitting the contact form. Please try again later."
        });
      }
    });
  };
  return (
    <ContactForm
      parentColor={parentColor}
      showNameField={showNameField}
      buttonText={buttonText}
      onSubmit={onSubmit}
      status={status}
      {...otherProps}
    />
  );
}

Contact.propTypes = {
  buttonText: PropTypes.string,
  parentColor: PropTypes.string,
  showNameField: PropTypes.bool
};

export default Contact;
