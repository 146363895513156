import React from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Section from "./../Section";
import Providers from "./../Providers";
import DigitalOcean from "../../images/logo-digitalocean.svg";
import Vultr from "../../images/logo-vultr.svg";
import Cloudflare from "../../images/logo-cloudflare.svg";
import Netlify from "../../images/logo-netlify.svg";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(6)
  },
}));

function ProvidersSection(props) {
  const { color, size, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Section color={color} size={size} {...otherProps}>
      <Container>
        <Typography
          color="textSecondary"
          variant="h6"
          align="center"
          gutterBottom
          className={classes.sectionTitle}
        >
          Industry Leading Providers
        </Typography>
        <Providers
          items={[
            {
              name: "DigitalOcean",
              alt: "DigitalOcean",
              image: `${DigitalOcean}`,
              width: "250px",
              link: "https://www.digitalocean.com/"
            },
            {
              name: "Vultr",
              alt: "Vultr",
              image: `${Vultr}`,
              width: "175px",
              link: "https://www.vultr.com/"
            },
            {
              name: "Cloudflare",
              alt: "Cloudflare",
              image: `${Cloudflare}`,
              width: "270px",
              link: "https://www.cloudflare.com/"
            },
            {
              name: "Netlify",
              alt: "Netlify",
              image: `${Netlify}`,
              width: "175px",
              link: "https://www.netlify.com/"
            }
          ]}
        />
      </Container>
    </Section>
  );
}

ProvidersSection.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

export default ProvidersSection;
