import React from "react";
import Helmet from "react-helmet";
import ContactSection from "../../components/ContactSection";
import config from "../../util/config.js";

function ContactPage() {
  return (
    <>
      <Helmet>
        <title>{config.meta.siteName} | Contact</title>
      </Helmet>
      <ContactSection
        color="background.alternate"
        size="medium"
        title="Contact Us"
        subtitle="Interested in working with us? Drop us a line below and briefly outline how we can help you!"
        showNameField={true}
        buttonText="Send message"
        contactInfo={config}
      />
    </>
  );
}

export default ContactPage;
